var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"id":"new_account_modal","visible":_vm.visible,"footer":null,"closable":false,"bodyStyle":{padding: 0, minHeight: _vm.modalHeight},"destroyOnClose":""}},[_c('div',{staticClass:"new-add-model__header-div"},[_c('span',{staticClass:"new-add-model__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('a-button',{staticClass:"new-add-model__button-cancel",on:{"click":_vm.handleCancle}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),(!_vm.showLink)?_c('a-button',{staticClass:"new-add-model__button-save",attrs:{"type":"primary","loading":_vm.subLoading},on:{"click":function($event){return _vm.handleOk('email')}}},[_vm._v(" "+_vm._s(_vm.$t('summary.acount.email.send'))+" ")]):_vm._e(),(!_vm.showLink)?_c('a-button',{staticClass:"new-add-model__button-save",attrs:{"type":"primary","loading":_vm.subLoading},on:{"click":function($event){return _vm.handleOk('link')}}},[_vm._v(" "+_vm._s(_vm.$t('summary.acount.email.link'))+" ")]):_vm._e()],1),_c('div',{class:_vm.type ===0 ? 'new-add-model__input-div--admin' : 'new-add-model__input-div'},[(!_vm.showLink)?_c('a-form',{attrs:{"form":_vm.form}},[_vm._l((_vm.form.getFieldValue('keys')),function(k,index){return _c('a-form-item',_vm._b({key:k,attrs:{"label":index === 0 ? _vm.$t('summary.acount.email') : '',"required":false}},'a-form-item',index === 0 ? _vm.formItemLayout : _vm.formItemLayoutWithOutLabel,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            ("names[" + k + "]"),
            {
              validateTrigger: ['change', 'blur'],
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: _vm.$t('summary.acount.placeholder.email')
                },
                {
                  pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z]+)+$/,
                  message: _vm.$t('summary.validation.email')
                } 
              ],
            } ]),expression:"[\n            `names[${k}]`,\n            {\n              validateTrigger: ['change', 'blur'],\n              rules: [\n                {\n                  required: true,\n                  whitespace: true,\n                  message: $t('summary.acount.placeholder.email')\n                },\n                {\n                  pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\\.[a-zA-Z]+)+$/,\n                  message: $t('summary.validation.email')\n                } \n              ],\n            },\n          ]"}],staticStyle:{"width":"60%","margin-right":"8px"},attrs:{"placeholder":_vm.$t('summary.acount.placeholder.email')}}),(_vm.form.getFieldValue('keys').length > 1)?_c('a-icon',{staticClass:"dynamic-delete-button",attrs:{"type":"minus-circle-o","disabled":_vm.form.getFieldValue('keys').length === 1},on:{"click":function () { return _vm.remove(k); }}}):_vm._e()],1)}),_c('a-form-item',_vm._b({},'a-form-item',_vm.formItemLayoutWithOutLabel,false),[_c('a-button',{staticStyle:{"width":"60%"},attrs:{"type":"dashed"},on:{"click":_vm.addEmailFiled}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" "+_vm._s(_vm.$t('summary.acount.email.new'))+" ")],1)],1)],2):_vm._e(),(_vm.showLink)?_c('div',_vm._l((_vm.linkList),function(item,idx){return _c('div',{key:idx,staticStyle:{"margin-bottom":"20px"}},[_c('div',{domProps:{"textContent":_vm._s(item.email)}}),_c('div',{staticStyle:{"width":"100%"}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-input',{staticStyle:{"width":"calc(100% - 100px)"},model:{value:(item.invite_link),callback:function ($$v) {_vm.$set(item, "invite_link", $$v)},expression:"item.invite_link"}}),_c('a-tooltip',{attrs:{"title":"copy"}},[_c('a-button',{on:{"click":function($event){return _vm.copyUrl(item.invite_link)}}},[_c('a-icon',{attrs:{"type":"copy"}})],1)],1)],1)],1)])}),0):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }