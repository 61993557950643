


















































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { commitSetLoading, commitSetUserProfile } from '../../../store/main/mutations'
import updateRanter from './summary/UpdateCompany.vue'
import AuthorityAccountList from './summary/AuthorityAccountList.vue'
import NewAcountModal from './summary/NewAcountModal.vue'
import InviteAcountModal from './summary/InviteAcountModal.vue'
import _ from 'lodash'
import { addUser, deleteMultiUser, deleteOneUser, updateUser, updateUserOrg, inviteUser } from '../../../interfaces/service/person/userAuthority'
import { getAllSubOrgIds, getGlobalUserType, getRequestErrCode, getRequestErrContent, to } from '../../../utils/util'
import { deleteAll, getRanterTreeData, disbandOrg } from '../../../interfaces/service/org/enterpriseMang'
import { readRanterId } from '@/store/main/getters'
import { getUserMe } from '@/interfaces/service/user/mainPage'
import { IUserProfile } from '@/interfaces'
import { dispatchUserLogOut } from '@/store/main/actions'

enum UserType {
  superuser,
  manager,
  operator
}

type addModeUnit = 'new' | 'edit'

@Component({
  components: {
    updateRanter,
    AuthorityAccountList,
    NewAcountModal,
    InviteAcountModal
  }
})
export default class Summary extends Vue {
  public windownWidth = document.body.clientHeight
  public loading: boolean = true
  public subLoading : boolean = false
  public userType : number = this.$store.state.main.userProfile.user_type
  public selectedRows: any[] = [] // 被选中的行
  // public selectedRowKeys: any[] = [] // 被选中的行的key
  public selectedLength: number = 0 // 被选中的行数 -- 控制删除按钮显示
  public deleteModalVisible: boolean = false
  public currentTabKey: string = '1' // 当前选中的tab

  public addVisible: boolean = false // 新增用户 modal
  public inviteVisible: boolean = false // 新增用户
  public addModalTitle: string = '' // 新增用户 modal 显示 title
  public inviteModalTitle: string = '' // 新增用户 modal 显示 title
  public addModalType: number = -1 // 新增用户 modal 类型
  public addModalMode: addModeUnit = 'new' // 新增用户 modal 类型
  public record: any = {}
  public disbandModalVisible: boolean = false
  public leaveModalVisible: boolean = false
  public tableData: any[] = []
  public userTypeMap = {
    superuser: UserType.superuser,
    manager: UserType.manager
  }
  public ranterName: any = '' // 租户名称

  public get ranterId() : number {
    let id: number = readRanterId(this.$store)
    if (id === -10) { id = +(sessionStorage.getItem('ranterId') || -10) }
    return id
  }

  // 1/监听路由变化
  @Watch('$route', { immediate: true })
  public changeRouter(route: any) {
    this.ranterName = route.query.ranterName

    if (this.currentTabKey === '2' || this.currentTabKey === '3') {
      this.refreshUser()
    }
  }

  @Watch('$store.state.main.screen.height') public windowWidth() {
    this.windownWidth = document.body.clientWidth
  }
  @Watch('$store.state.main.waitloading')
  public resetLoading() {
    this.subLoading = this.$store.state.main.waitloading
  }
  public navTabChange(activeKey) {
    // nav变化监听
    this.currentTabKey = activeKey
  }
  public updateRanter() {
    // 更新企业信息
  }

  /**
   * 管理员一览表格挂载完毕，回传表格数据
   */
  public handleDataChange(data) {
    this.tableData = data
  }

  /**
   * table 选中发生变化
   */
  public handleRowChange(selectedRowKeys, selectedRows) {
    this.selectedRows = selectedRows
    this.selectedLength = selectedRows.length
  }

  /**
   * 点击表格内的编辑按钮
   */
  public handleRowEdit(record, type) {
    this.addVisible = true
    this.addModalTitle = this.$i18n.tc(`summary.acount.edit.${getGlobalUserType(type)}`)
    this.addModalMode = 'edit'
    this.record = record
    this.addModalType = type
  }

  /*------------------- 新增用户 modal ---------------------- */

  /**
   * 新增用户
   */
  public handleAdd(type) {
    this.addModalMode = 'new'
    this.addModalTitle = this.$i18n.tc(`summary.acount.create.${getGlobalUserType(type)}`)
    this.addVisible = true
    this.addModalType = type
  }

  public handleAddInvite(type) {
    this.addModalMode = 'new'
    this.inviteModalTitle = this.$i18n.tc(`summary.header.button.invite.admin`)
    this.inviteVisible = true
    this.addModalType = type
  }


  public async handleInviteOk(data) {
    if (!data || data.length === 0 ) { return }
    commitSetLoading(this.$store, true)
    try {
      const orgId = this.ranterId
      const emails = {
        emails: data
      }
      const [err, res] = await to(inviteUser(orgId, emails))
      if (err) {
        this.$message.error(this.$i18n.tc('summary.validation.invite.fail'))
      } else {
        this.$message.success(this.$i18n.tc('summary.validation.invite.sendOk'))
      }
    } catch (e) {
      console.log(e)
    }
    commitSetLoading(this.$store, false)
    this.inviteVisible = false
  }
  /**
   * 确认新增用户
   */
  public async handleAddOk(form) {
    // 表单校验
    if (!this.validate(form)) { return }

    // // 获取该租户下所有子组织id
    // const enterpriseHashData: any = this.$store.state.main.enterprise.enterpriseHashData
    // const fattenedIds = getAllSubOrgIds(enterpriseHashData, this.ranterId)

    // // 表单校验通过
    // form.org_ids = fattenedIds
    // 绑定用户和该租户所有组织的关系
    commitSetLoading(this.$store, true)

    let err
    let addRes
    let updateRes

    if (this.addModalMode === 'new') {
      // 新建用户
      // 管理员用户 设置用组织绑定信息
      if (form.user_type === this.userTypeMap.manager) {
        form.org_ids = form.org_ids[0]
      }

      [err, addRes] = await to(addUser(form))

      if (err) {
        // 新建用户发生错误
        this.$message.error(this.$i18n.tc('summary.validation.email.isExisted'))
        commitSetLoading(this.$store, false)
        return
      }

    } else {
      // 更新用户
      // 更新用户基本信息
      [err, updateRes] = await to(updateUser(this.record.id, form))
      // 管理员用户 更新用户绑定组织信息
      if (form.user_type === this.userTypeMap.manager) {
        await to(updateUserOrg(this.record.id, form.org_ids[0]))
      }
    }

    if (err) {
      // 更新用户发生错误
      this.$message.error(this.$i18n.tc('summary.validation.email.isExisted'))
      commitSetLoading(this.$store, false)
      return
    }

    this.addVisible = false
    this.clearForm()
    this.refreshUser()
    this.record = {}
    commitSetLoading(this.$store, false)

    // 刷新当前用户信息
    const userRes: any = await getUserMe()
    const { email, id, user_type, activated, first_name, last_name, orgs, login } = userRes

    const userData: IUserProfile = {
      email, id, user_type, activated, first_name, last_name, full_name: first_name + last_name, orgs, login
    }

    commitSetUserProfile(this.$store, userData)
  }

  /**
   * 取消新增用户
   */
  public handleAddCancle() {
    this.addVisible = false
    this.inviteVisible = false
    this.record = {}
  }

  /*------------------- 删除用户 modal ---------------------- */

  /**
   * 删除用户
   */
  public handleDelete() {
    this.deleteModalVisible = true
  }

  /**
   * deleteModalOk
   */
  public async deleteModalOk() {
    commitSetLoading(this.$store, true)
    const selectedIds = _.map(this.selectedRows, ((item) => {
      return item.id
    }))

    // 不能删除自己
    const userId = this.$store.state.main.userProfile.id
    if (selectedIds.includes(userId)) {
      this.deleteModalVisible = false
      this.$message.error(this.$i18n.tc('summary.validation.delete_user'))
      return
    }

    await deleteMultiUser(selectedIds)

    // 重新获取数据
    this.refreshUser()
    commitSetLoading(this.$store, false)
    this.deleteModalVisible = false
  }

  /**
   * 取消删除用户
   */
  public deleteModalCancle() {
    this.deleteModalVisible = false
  }

  /*---------------------- 解散组织--- ---------------------- */
  /**
   * 解散组织
   */
  public handleDisband() {
    this.disbandModalVisible = true
  }

  /**
   * 确认解散组织
   */
  public async disbandModalOk() {
    commitSetLoading(this.$store, true)
    this.disbandModalVisible = false
    const id = this.ranterId
    // const enterpriseHashData: any = this.$store.state.main.enterprise.enterpriseHashData
    // const fattenedIds = getAllSubOrgIds(enterpriseHashData, id)

    // deleteAll(this.$store.state.main.userProfile.user_type, fattenedIds)

    // // 删除完成后，切换到第一个租户
    // const rantersTree: any[] = await getRanterTreeData(this.userType)

    // // 解散后没有租户存在
    // if (_.isEmpty(rantersTree)) {
    //   this.$router.push({ path: '/home-header/home-blank' })
    //   // 重置数据
    //   this.$store.commit('setRanterId', -10)
    //   sessionStorage.setItem('ranterId', '-10')
    //   this.$store.commit('setRanterName', '')
    //   sessionStorage.setItem('ranterNameChose', '')
    //   commitSetLoading(this.$store, false)
    //   return
    // }

    // // 解散后有租户存在
    // const ranterId = rantersTree[0].id
    // const ranterName = rantersTree[0].name
    // this.ranterName = ranterName
    // // 同步ranterId
    // this.$store.commit('setRanterId', ranterId)
    // sessionStorage.setItem('ranterId', ranterId)
    // localStorage.setItem('ranterNameChose', ranterName)
    // this.$store.commit('setRanterName', ranterName)
    // // 重置前画面组织 tree 选中状态
    // this.$store.commit('setSelectedKeys', [])
    // localStorage.setItem('selectedKeys', '[]')
    // // 重置前画面选中子组织状态
    // localStorage.setItem('childOrgChoseStore', '')
    const [err] = await to(disbandOrg(id))
    commitSetLoading(this.$store, false)
    if (!err) {
      this.$router.push({ path: '/home-header/home' })
    }
    // if (this.$refs.userTable) {
    //   this.refreshUser()
    // }

    // if (this.$refs.updateRanter) {
    //   this.refreshRanter()
    // }
  }

  /**
   * 取消解散组织
   */
  public disbandModalCancle() {
    this.disbandModalVisible = false
  }

  /*---------------------- 退出组织--- ---------------------- */
  /**
   * 退出组织
   */
  public handleLeave() {
    this.leaveModalVisible = true
  }

  /**
   * 确认退出组织
   */
  public async leaveModalOk() {
    if (this.tableData.length === 1) {
      this.leaveModalVisible = false
      this.$message.error(this.$i18n.tc('summary.validation.leave'))
      return
    }

    const {orgs, id} = this.$store.state.main.userProfile

    // 解除该登录用户和租户的绑定关系 不存在其他绑定关系删除当前用户
    let restOrgIds = orgs.map((item) => {
      if (item.org_id !== this.ranterId) {
        return item.org_id
      }
    })

    restOrgIds = _.compact(restOrgIds)

    // 存在其他绑定组织 解除当前组织绑定
    if (restOrgIds.length > 0) {
      const [err] = await to(updateUserOrg(id, restOrgIds))

      if (err) {
        // console.log(err);
        return
      }
      this.$router.push({ path: '/home-header/home' })
      return
    }

    // 不存在其他绑定组织 删除当前登录用户
    await deleteOneUser(id)
    // this.deleteModalVisible = false
    this.leaveModalVisible = false
    // 退出登录
    dispatchUserLogOut(this.$store)
  }

  /**
   * 取消退出组织
   */
  public leaveModalCancle() {
    this.leaveModalVisible = false
  }

  private validate(form): boolean {
  // 表单校验
    const emailRegex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z]+)+$/
    const telRegex = /^[0-9\s-+]*$/
    // 邮箱合法性校验
    if (!emailRegex.test(form.email)) {
      this.$message.error(this.$i18n.tc('summary.validation.email'))
      return false
    }

    // 当输入电话后，校验电话合法性
    if (form.phone) {
      if (!telRegex.test(form.phone)) {
        this.$message.error(this.$i18n.tc('summary.validation.phone'))
        return false
      }
    }

    // 新建管理员时是否选择了组织关系
    if (form.user_type === this.userTypeMap.manager) {
      if (form.org_ids[0].length < 1 && form.org_ids[1].length < 1) {
        this.$message.error(this.$i18n.tc('user.add_modify_draw.error_org'))
        return false
      }
    }

    return true
  }

  /**
   * 刷新用户数据
   */
  private refreshUser() {
    (this.$refs.userTable as Vue & {handleRequests: () => void}).handleRequests()
  }

  private refreshRanter() {
    (this.$refs.updateRanter as Vue & {dataFresh: () => void}).dataFresh()
  }

  private clearForm() {
    (this.$refs.accountModal as Vue & {clearForm: () => void}).clearForm()
  }

  // VUE 生命周期
  private created() {
    commitSetLoading(this.$store, true)
    commitSetLoading(this.$store, false)
  }
}
